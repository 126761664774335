<div class="recent-bets-container" *ngIf="bets" data-testid="my-bets-recent-bets">
  <div class="filter-bets">
    <input type="text" placeholder="Filter Bets" [(ngModel)]="filterBets" />
  </div>
  <app-loading-bar [show]="loading"></app-loading-bar>
  <ng-container *ngIf="!loading">
    <div class="bet-content">
      <div class="wrapper">
        <my-bets-recent-bet
          *ngFor="let item of bets | filterSearch: filterBets; trackBy: myBetsTrackBy"
          [item]="item"
          [collapsible]="true"
          [filterBets]="filterBets"
          [loadCollapsed]="!autoExpandCoupon(item.couponCode)"
          parentComponent="my_bets"
          (getBetDetails)="getBetDetails($event)"
          (toggleBetInfo)="toggleBetInfo($event)"
        ></my-bets-recent-bet>
      </div>
    </div>

    <my-bets-no-bets
      *ngIf="bets.length === 0"
      i18n-tabName
      data-testid="my-bets-bo-bets"
      [tabName]="isSettled ? betsTab.Settled : betsTab.Open"
      [productName]="productTabSelected"
    ></my-bets-no-bets>
  </ng-container>
</div>
