<app-breadcrumb *ngIf="!hideBreadcrumb" i18n-title title="My Bets" [darkMode]="true" [showIcons]="false"
  [showBreadcrumbs]="false"></app-breadcrumb>

<div class="my-bets-wrapper">
  <div class="my-bets-header" *ngIf="!hideHeader">
    <span class="my-bets-title" i18n>My Bets</span>
    <span class="my-bets-close material-icons" (click)="goBack()">{{ 'close' }}</span>
  </div>

  <div class="top-panel">
    <div class="bets-tab-container">
      <div class="bets-tab selected" [class.selected]="(myBetsQuery.selectedBetsTab$ | async) === betsTab.Open"
        data-testid="my-bets-open-bets" (click)="changeBetsTab(betsTab.Open)" i18n>
        Open
      </div>
      <div class="bets-tab" [class.selected]="(myBetsQuery.selectedBetsTab$ | async) === betsTab.Settled"
        data-testid="my-bets-settled-bets" (click)="changeBetsTab(betsTab.Settled)" i18n>
        Settled
      </div>
    </div>
  </div>

  <div class="main-content" [ngClass]="{ authenticated: (accountQuery.isAuthenticated$ | async) }">
    <div class="product-tabs-container" appAdjustTabOnSelected>
      <div class="product-tab" *ngFor="let product of (productList$ | async)"
        [attr.data-testid]="'my-bets-product-' + (product | kebabCase)"
        [class.selected]="(myBetsQuery.selectedProductTab$ | async) === product" (click)="changeProductTab(product)">
        <div class="product-name" i18n>{{ product }}</div>
      </div>
    </div>

    <ng-container [ngSwitch]="(myBetsQuery.selectedBetsTab$ | async)">
      <my-bets-recent-bets *ngSwitchCase="betsTab.Open" class="recent-bets" [bets]="myBetsQuery.myOpenBetsView$ | async"
        [loading]="myBetsQuery.openLoading$ | async">
      </my-bets-recent-bets>
      <my-bets-recent-bets *ngSwitchCase="betsTab.Settled" class="recent-bets" [bets]="myBetsQuery.mySettledBets$ | async"
        [loading]="myBetsQuery.settledLoading$ | async" [isSettled]="true"
        [autoExpandCouponCodes]="myBetsQuery.autoExpandCouponCodes$ | async"></my-bets-recent-bets>
    </ng-container>
  </div>
</div>
