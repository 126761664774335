import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { APIService } from 'src/app/core/services/api.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { MyBetsStore } from 'src/app/modules/my-bets/state/my-bets.store';
import { APIType } from 'src/app/shared/models/api.model';

@Injectable({
  providedIn: 'root',
})
export class VirtualsMyBetsService implements OnDestroy {
  private readonly virtualsConfig = this.appConfigService.get('virtuals');
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly apiService: APIService,
    private readonly appConfigService: AppConfigService,
    private readonly myBetsStore: MyBetsStore
  ) {}

  getMyBetsCount() {
    // API not available to retrieve the open bets count in Virtuals, so fetching its list
    this.getOpenCount().subscribe(data => {
      this.myBetsStore.updateOpenBetsCount(data.Count);
    });
  }

  addNewBet(couponCode: string): void {
    this.myBetsStore.addNewBet(couponCode);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private getOpenCount(): Observable<any> {
    return this.apiService.get<any>(APIType.VirtualsBetSearch, 'v1/coupons/running/count').pipe(takeUntil(this.destroy$));
  }
}
