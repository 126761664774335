import { Injectable } from '@angular/core';
import { applyTransaction, ID, Store, StoreConfig } from '@datorama/akita';
import { MyOpenBetsStore } from 'src/app/modules/my-bets/state/my-open-bets.store';
import { MySettledBetsStore } from 'src/app/modules/my-bets/state/my-settled-bets.store';
import { CouponStatus } from 'src/app/shared/models/coupon-details.model';
import { BetDetailsModel, MyBetsContentModel, RecentBetModel } from 'src/app/modules/my-bets/models/my-bets.model';
import { MyBetsState } from 'src/app/modules/my-bets/models/my-bets-state.model';
import { BetsTab } from 'src/app/modules/my-bets/models/my-bets-enums.model';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { ProductType, VirtualsLeagueType } from 'src/app/shared/models/product.model';

function createInitialState(): MyBetsState {
  return {
    myBetsContent: undefined,
    selectedBetsTab: BetsTab.Open,
    selectedProductTab: ProductType.JackpotBets,
    virtualsLeagueTabs: [],
    selectedVirtualsLeagueTab: undefined,
    autoExpandCouponCodes: [],
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'my-bets' })
export class MyBetsStore extends Store {
  constructor(
    private readonly applicationQuery: ApplicationQuery,
    private readonly myOpenBetsStore: MyOpenBetsStore,
    private readonly mySettledBetsStore: MySettledBetsStore
  ) {
    super(createInitialState());
  }

  setOpenBets(myBetsData: RecentBetModel[]): void {
    this.myOpenBetsStore.updateMyBetsCount(myBetsData.length);
    this.myOpenBetsStore.set(myBetsData);
  }

  setOpenLoading(loading: boolean = true): void {
    this.myOpenBetsStore.setLoading(loading);
  }

  setSettledBets(myBetsData: RecentBetModel[]): void {
    this.mySettledBetsStore.set(myBetsData);
  }

  addLostBets(myBetsData: RecentBetModel[]): void {
    applyTransaction(() => {
      this.mySettledBetsStore.setLoading(false);
      this.mySettledBetsStore.update({ lostBetsLoaded: true });
      this.mySettledBetsStore.add(myBetsData);
    });
  }

  setSettledLoading(loading: boolean = true): void {
    this.mySettledBetsStore.setLoading(loading);
  }

  addNewBet(couponCode: string): void {
    const currBetCount = this.myOpenBetsStore.getValue().betCount;
    this.myOpenBetsStore.updateMyBetsCount(currBetCount + 1);
    this.myOpenBetsStore.upsert(couponCode, new RecentBetModel({ couponCode }));
  }

  removeOpenBet(couponCode: string): void {
    this.myOpenBetsStore.removeByCouponCode(couponCode);
  }

  updateBetDetails(id: ID, betDetails: BetDetailsModel, couponStatus: CouponStatus): void {
    const store = this.getStoreFromCouponStatus(couponStatus);

    store.setActive(id);
    if (betDetails) {
      store.updateActive(() => ({
        betDetails,
      }));
    }
  }

  setActive(bet: RecentBetModel): any {
    const store = this.getStoreFromCouponStatus(bet.couponStatusId);

    store.setActive(bet.id);
    return store;
  }

  toggleCollapsed(bet: RecentBetModel): void {
    const store = this.setActive(bet);
    store.toggleCollapsed();
  }

  toggleBetInfoCollapsed(bet: RecentBetModel): void {
    const store = this.setActive(bet);
    store.toggleBetInfoCollapsed();
  }

  clearOpenBets(): void {
    this.myOpenBetsStore.clear();
  }

  clearSettledBets(): void {
    this.mySettledBetsStore.clear();
  }

  clear(): void {
    this.myOpenBetsStore.clear();
    this.mySettledBetsStore.clear();
  }

  changeBetsTab(selectedBetsTab: BetsTab): void {
    this.update({ selectedBetsTab });
  }

  changeProductTab(selectedProductTab: ProductType): void {
    this.update({ selectedProductTab });
  }

  updateOpenBetsCount(count: number): void {
    this.myOpenBetsStore.updateMyBetsCount(count);
  }

  updateMyBetsContent(myBetsContent: MyBetsContentModel): void {
    this.update({ myBetsContent });
  }

  clearOpenExpandedBets(): void {
    this.myOpenBetsStore.update(null, { collapsed: true });
  }

  setAutoExpandCouponCodes(autoExpandCouponCodes: string[]): void {
    this.update({ autoExpandCouponCodes });
  }

  clearAutoExpandCouponCodes(): void {
    this.update({ autoExpandCouponCodes: [] });
  }

  addAutoExpandCouponCode(couponCode: string): void {
    this.update(state => ({
      autoExpandCouponCodes: [...state.autoExpandCouponCodes, couponCode],
    }));
  }

  removeAutoExpandCouponCode(couponCode: string): void {
    this.update(state => ({
      autoExpandCouponCodes: state.autoExpandCouponCodes.filter(c => c !== couponCode),
    }));
  }

  setVirtualsLeagueTabs(virtualsLeagueTabs: VirtualsLeagueType[]): void {
    this.update({ virtualsLeagueTabs });
  }

  changeVirtualsLeagueTab(selectedVirtualsLeagueTab: VirtualsLeagueType): void {
    this.update({ selectedVirtualsLeagueTab });
  }

  setDefaultVirtualsLeagueTab(): void {
    this.update(state => ({
      selectedVirtualsLeagueTab: state.virtualsLeagueTabs?.length ? state.virtualsLeagueTabs[0] : undefined,
    }));
  }

  private getStoreFromCouponStatus(couponStatusId: CouponStatus): MyOpenBetsStore | MySettledBetsStore {
    return couponStatusId === CouponStatus.Running || couponStatusId === CouponStatus.ReOpened
      ? this.myOpenBetsStore
      : this.mySettledBetsStore;
  }
}
