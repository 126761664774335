import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { ID } from '@datorama/akita';
import { Subject } from 'rxjs';
import { MyBetsQuery } from 'src/app/modules/my-bets/state/my-bets.query';
import { expandCollapse } from 'src/app/shared/animations';
import { RecentBetModel } from 'src/app/modules/my-bets/models/my-bets.model';
import { BetsTab } from 'src/app/modules/my-bets/models/my-bets-enums.model';
import { ProductType } from 'src/app/shared/models/product.model';
import { JackpotBetsMyBetsService } from 'src/app/modules/my-bets/services/my-bets-jackpotbets.service';

@Component({
  selector: 'my-bets-recent-bets',
  templateUrl: './recent-bets.component.html',
  styleUrls: ['./recent-bets.component.scss'],
  animations: [expandCollapse()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecentBetsComponent implements OnDestroy {
  @Input() bets: RecentBetModel[];
  @Input() loading: boolean;
  @Input() isSettled: boolean = false;
  @Input() productTabSelected: ProductType;
  @Input() autoExpandCouponCodes: string[] = [];

  readonly betsTab = BetsTab;

  filterBets: string = '';

  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(readonly myBetsQuery: MyBetsQuery, private readonly jackpotBetsMyBetsService: JackpotBetsMyBetsService) {}

  getBetDetails(item: RecentBetModel): void {
    this.jackpotBetsMyBetsService.toggleCollapsed(item);
  }

  toggleBetInfo(item: RecentBetModel): void {
    this.jackpotBetsMyBetsService.toggleBetInfoCollapsed(item);
  }

  myBetsTrackBy(index: number, item: RecentBetModel): ID {
    return item.couponCode;
  }

  isRecentBetRebetEnabled(item: RecentBetModel): boolean {
    return this.jackpotBetsMyBetsService.isRecentBetRebetEnabled(item);
  }

  autoExpandCoupon(couponCode: string): boolean {
    return this.autoExpandCouponCodes?.indexOf(couponCode) > -1;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
